<template>
  <div class="margin-top-30 padding-bottom-30">

    <div style="margin: 0 5vw;">
      <div class="grid-content-div item-label-wrap" v-if="simpleInfo.certificationStatus === 1">
      <span class="cursor-pointer margin-right-30 item-label" :class="showDiv === item.id?'active':''" @click="showDiv = item.id"
            v-for="(item,index) in [{id:0,name:'基础信息'},{id:1,name:'经营信息'}]" :key="index">{{item.name}}</span>
      </div>
      <div class="grid-content-div" v-show="showDiv === 0">
        <el-form label-width="160px">
          <h2>账号信息</h2>
          <el-form-item label="掌柜号" class="form-item">
            <div>{{simpleInfo.account}}</div>
          </el-form-item>
          <el-form-item label="类型" class="form-item" v-if="simpleInfo.type">
            <div>{{supplierType[simpleInfo.type]}}</div>
          </el-form-item>
          <el-form-item label="联系人" class="form-item" v-if="simpleInfo.contactPerson">
            <div>{{simpleInfo.contactPerson}}</div>
          </el-form-item>
          <el-form-item label="手机号" class="form-item" v-if="simpleInfo.contactPhone">
            <div>{{simpleInfo.contactPhone}}</div>
          </el-form-item>
          <el-form-item label="原始ID" class="form-item" v-if="simpleInfo.id">
            <div>{{simpleInfo.id}}</div>
          </el-form-item>
          <el-form-item label="登录密码">
            ******
            <el-link type="danger" icon="el-icon-edit" :underline="false" @click="editPassword()">修改</el-link>
          </el-form-item>
        </el-form>
      </div>
      <div class="grid-content-div" v-show="showDiv === 0 && hasLoadStoreInfo">
        <el-form :model="storeInfo" ref="storeFormName" label-width="120px">
          <h2>店铺信息</h2>
          <el-row>
            <el-col :span="12">

              <el-form-item label="店铺名称" class="form-item" :rules="formRules.name" prop="name">
                <el-input v-model="storeInfo.name" class="input-width-300"></el-input>
              </el-form-item>

              <el-form-item label="店铺简介" class="form-item" :rules="formRules.required" prop="brief">
                <el-input v-model="storeInfo.brief"></el-input>
              </el-form-item>

              <el-form-item label="店铺logo" class="form-item" :rules="formRules.required" prop="logo">
                <upload-images-one class="left"
                                   :file.sync="storeInfo.logo"
                                   isCropper
                                   :canvas="baseInfoLogo"
                                   width="100px"
                                   :show-hint-text="true"></upload-images-one>
              </el-form-item>

              <el-form-item v-if="storeInfo.specialtyPavilionName" label="特产馆" class="form-item">
                <div>{{storeInfo.specialtyPavilionName}}</div>
              </el-form-item>

              <el-form-item v-if="storeInfo.commentScore" label="店铺评分" class="form-item">
                <el-rate v-model="storeInfo.commentScore" :max="5" disabled show-score text-color="#ff9900" style="font-size: 30px;"> </el-rate>
              </el-form-item>

              <el-form-item label="所在区域" :rules="formRules.required" class="form-item" prop="townCode">
                <el-input v-model="area" disabled></el-input>
                <!-- <el-cascader class="width-all"
                             ref="region"
                             v-if="hasLoadStoreInfo"
                             v-model="regionValue"
                             :props="{lazy: true,value:'id',label:'name',lazyLoad}" @change="regionChange"></el-cascader> -->
              </el-form-item>

              <el-form-item label="详细地址" :rules="formRules.required" class="form-item" prop="address">
                <el-input v-model="storeInfo.address"></el-input>
                <!-- <el-button type="primary" style="margin-left: 10px;" @click="getAddressLatLng">定位</el-button> -->
              </el-form-item>
              <el-form-item style="width:90%;">
                <el-button type="primary" @click="submitForm('storeFormName')">保存</el-button>
                <!-- <el-button @click="resetForm('storeFormName')">重置</el-button> -->
              </el-form-item>

            </el-col>
            <!-- <el-col :span="6">
              <div id="mapBox" style="height:450px;width:650px;"></div>
            </el-col> -->
          </el-row>
        </el-form>
      </div>
      <div class="grid-content-div" v-show="showDiv === 1" style="position: relative">
        <el-form label-width="160px">
          <h2>主体信息</h2>
          <el-form-item label="主体类型" class="form-item">
            <div>{{companyType[qualificationJson.companyType]}}</div>
          </el-form-item>
          <el-form-item label="主体名称" class="form-item">
            <div>{{qualificationJson.companyName}}</div>
          </el-form-item>
          <el-form-item label="营业执照号码" class="form-item">
            <div>{{qualificationJson.businessLicenseNo}}</div>
          </el-form-item>
          <el-form-item label="营业执照" class="form-item" v-if="qualificationJson.businessLicenseImg">
            <el-link :href="qualificationJson.businessLicenseImg" type="primary" :underline="false" target="_blank">查看</el-link>
          </el-form-item>
          <el-form-item label="食品流通许可证" class="form-item" v-if="qualificationJson.circulationPermitImg">
            <el-link :href="qualificationJson.circulationPermitImg" type="primary" :underline="false" target="_blank">查看</el-link>
          </el-form-item>
          <el-form-item label="纳税人类型" class="form-item">
            <div>{{taxpayerType[qualificationJson.taxpayerType]}}</div>
          </el-form-item>
          <el-form-item label="一般纳税人证明材料" class="form-item" v-if="qualificationJson.normalTaxpayerImg">
            <el-link :href="qualificationJson.normalTaxpayerImg" type="primary" :underline="false" target="_blank">查看</el-link>
          </el-form-item>

        </el-form>
        <div class="change_wrp">
          <el-button v-if="!isUpdateCompanyInfo" type="danger" round plain @click="openDrawer">去修改</el-button>
          <el-button v-if="isUpdateCompanyInfo" round plain>审核中</el-button>
          <div v-if="isUpdateCompanyInfo" class="el-form-item--small" style="font-size: 12px; color: #999;">资料正在审核中,请等待1-2个工作日！</div>
          <div v-else-if="lastAuditStatus == 4" class="el-form-item--small" style="font-size: 12px; color: #999;">上次提交资料审核未通过</div>
        </div>
      </div>
      <div class="grid-content-div" v-show="showDiv === 1">
        <el-form label-width="160px">
          <h2>经营者/法人身份证信息</h2>
          <el-form-item label="身份证人像面" class="form-item" v-if="qualificationJson.operatorsCardPositiveImg">
            <el-link :href="qualificationJson.operatorsCardPositiveImg" type="primary" :underline="false" target="_blank">查看</el-link>
          </el-form-item>
          <el-form-item label="身份证国徽面" class="form-item" v-if="qualificationJson.operatorsCardBackImg">
            <el-link :href="qualificationJson.operatorsCardBackImg" type="primary" :underline="false" target="_blank">查看</el-link>
          </el-form-item>
          <el-form-item label="姓名" class="form-item">
            <div>{{qualificationJson.operatorsName}}</div>
          </el-form-item>
          <el-form-item label="身份证号" class="form-item">
            <div>{{qualificationJson.operatorsCardNo}}</div>
          </el-form-item>
          <el-form-item label="手机号" class="form-item">
            <div>{{qualificationJson.operatorsPhone}}</div>
          </el-form-item>
          <el-form-item label="邮箱" class="form-item">
            <div>{{qualificationJson.operatorsEmail}}</div>
          </el-form-item>
        </el-form>
      </div>
      <div class="grid-content-div" v-show="showDiv === 1">
        <el-form label-width="160px">
          <h2>结算账户信息</h2>
          <el-form-item label="开户行" class="form-item">
            <div>{{settlementJson.bankName}}</div>
          </el-form-item>
          <el-form-item label="开户支行" class="form-item">
            <div>{{settlementJson.bankBranch}}</div>
          </el-form-item>
          <el-form-item label="名称" class="form-item">
            <div>{{settlementJson.bankHolderName}}</div>
          </el-form-item>
          <el-form-item label="账号" class="form-item">
            <div>{{settlementJson.bankCard}}</div>
          </el-form-item>
          <el-form-item label="账户类型" class="form-item">
            <div>{{bankType[settlementJson.bankType]}}</div>
          </el-form-item>
          <el-form-item label="开户许可证" class="form-item" v-if="settlementJson.openPermitImg">
            <el-link :href="settlementJson.openPermitImg" type="primary" :underline="false" target="_blank">查看</el-link>
          </el-form-item>

        </el-form>
      </div>
    </div>

    <el-drawer
      title="修改经营信息"
      class="drawer-scroll-y"
      :visible.sync="showUpdateCompanyDialog"
      :close-on-press-escape="false"
      :wrapperClosable="false"
      :destroy-on-close="true"
      ref="drawer"
      size="calc(80%)">
      <div class="drawer-scroll-y-box" style="height: 95%;background-color: #FEFEFE;">
        <el-form class="form-add-new"
                 style="padding: 5px;"
                 :model="form"
                 ref="companyUpdateForm"
                 label-width="140px">

          <div style="width: 80vh">

            <div class="flex-item-form-header">
              <div class="content">主体资质</div>
            </div>
            <el-form-item label="主体类型" class="form-item" :rules="formRules.required" prop="qualificationJson.companyType">
              <el-select size="small" v-model="form.qualificationJson.companyType" placeholder="请选择" style="width: 200px"
                         @change="changeCompanyType">
                <el-option
                  v-for="item in companyTypeArray"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="主体名称" class="form-item" :rules="formRules.required" prop="qualificationJson.companyName">
              <el-input v-model="form.qualificationJson.companyName" @blur="blurContactPerson"></el-input>
              <div  class="color-info">请填写营业执照上的名称</div>
            </el-form-item>

            <el-form-item label="经营简称" class="form-item" :rules="formRules.shortName" prop="qualificationJson.shortName">
              <el-input v-model="form.qualificationJson.shortName" @blur="blurContactPerson"></el-input>

            </el-form-item>
            <el-form-item
              label="营业执照类型"
              class="form-item"
              :rules="formRules.required"
              prop="qualificationJson.businessLicenseType"
            >
              <el-select
                size="small"
                v-model="form.qualificationJson.businessLicenseType"
                placeholder="请选择"
                style="width: 200px"
              >
                <el-option
                  v-for="item in businessLicenseTypeArray"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="营业执照号码" class="form-item" :rules="formRules.required" prop="qualificationJson.businessLicenseNo">
              <el-input v-model="form.qualificationJson.businessLicenseNo" ></el-input>
              <div  class="color-info">请填写营业执照上的15位编号</div>
            </el-form-item>

            <el-form-item label="营业执照" class="form-item" :rules="formRules.required" prop="qualificationJson.businessLicenseImg">
              <upload-images-one class="left"
                                 :file.sync="form.qualificationJson.businessLicenseImg"
                                 width="100px"
                                 :show-hint-text="true"></upload-images-one>
            </el-form-item>
            <el-form-item
              label="营业期限"
              class="form-item"
              :rules="formRules.required"
              prop="qualificationJson.businessHoursStart"
            >
              <el-date-picker
                v-model="form.qualificationJson.businessHoursStart"
                type="date"
                value-format="timestamp"
                placeholder="开始日期">
              </el-date-picker>
              <span style="margin: 0 10px;">至</span>
              <el-date-picker
                v-model="form.qualificationJson.businessHoursEnd"
                type="date"
                value-format="timestamp"
                :disabled="noBusinessHoursEnd"
                @change="dateChange"
                :placeholder="endData">
              </el-date-picker>
              <el-checkbox style="margin-left: 20px;" @change="radioChange"  v-model="noBusinessHoursEnd">长期</el-checkbox>
            </el-form-item>
            <el-form-item
                label="注册地址"
                :rules="formRules.required"
                class="form-item"
                prop="areaList"
              >
                <el-cascader
                  class="width-all"
                  ref="businessAddress"
                  v-model="form.areaList"
                  :props="{ lazy: true, value: 'id', label: 'name', emitPath:false, lazyLoad: lazyLoad3 }"
                  @change="regionYYChange"
                ></el-cascader>
            </el-form-item>
            <el-form-item
              label="注册详细地址"
              class="form-item"
              :rules="formRules.addressInfo"
              prop="addressInfo"
            >
              <el-input v-model="storeInfo.addressInfo" @input="change($event)"></el-input>
            </el-form-item>

            <el-form-item v-if="form.qualificationJson.businessLicenseType == 1" label="组织机构代码证" class="form-item" :rules="formRules.required" prop="qualificationJson.organizationImg">
              <upload-images-one class="left"
                                 :file.sync="form.qualificationJson.organizationImg"
                                 width="100px"
                                 :show-hint-text="true"></upload-images-one>
            </el-form-item>
            <el-form-item label="食品流通许可证" class="form-item" prop="qualificationJson.circulationPermitImg">
              <upload-images-one class="left"
                                 :file.sync="form.qualificationJson.circulationPermitImg"
                                 width="100px"
                                 :show-hint-text="true"></upload-images-one>
            </el-form-item>

            <el-form-item label="纳税人类型" class="form-item" :rules="formRules.required" prop="qualificationJson.taxpayerType">
              <el-select size="small" v-model="form.qualificationJson.taxpayerType" placeholder="请选择" style="width: 200px" >
                <el-option
                  v-for="item in taxpayerTypeArray"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="一般纳税人证明材料" class="form-item"
                          v-if="form.qualificationJson.taxpayerType === 2"
                          :rules="formRules.required"
                          prop="qualificationJson.normalTaxpayerImg">
              <upload-images-one class="left"
                                 :file.sync="form.qualificationJson.normalTaxpayerImg"
                                 width="100px"
                                 :show-hint-text="true"></upload-images-one>
            </el-form-item>

            <div class="flex-item-form-header">
              <div class="content">
                法人/经营者资质
              </div>
              <small>公司类型填法人代表信息, 个体户填写经营者信息</small>
            </div>

            <div class="flex-item align-items-start">
              <div class="form-item-label text-right" style="min-width: 160px;">
                身份证图片：
              </div>
              <el-form-item label="身份证人像面"
                            label-width="0"
                            class="form-item max-width left form-item-label-row"
                            prop="qualificationJson.operatorsCardPositiveImg" :rules="formRules.required">
                <upload-images-one class="left"
                                   :file.sync="form.qualificationJson.operatorsCardPositiveImg"
                                   width="100px"
                                   :show-hint-text="true"></upload-images-one>
              </el-form-item>
              <el-form-item label="身份证国徽面"
                            label-width="0"
                            class="form-item max-width left form-item-label-row"
                            prop="qualificationJson.operatorsCardBackImg" :rules="formRules.required">
                <upload-images-one class="left"
                                   :file.sync="form.qualificationJson.operatorsCardBackImg"
                                   width="100px"
                                   :show-hint-text="true"></upload-images-one>
              </el-form-item>
            </div>

            <el-form-item label="证件有效期" class="form-item" required>
              <el-date-picker
                v-model="form.qualificationJson.personHoursStart"
                type="date"
                value-format="timestamp"
                placeholder="开始时间"
              >
              </el-date-picker>
              <span style="margin: 0 10px">至</span>
              <el-date-picker
                v-model="form.qualificationJson.personHoursEnd"
                type="date"
                :placeholder="noPersonHoursEnd ? '长期' : '结束时间'"
                value-format="timestamp"
                :disabled="noPersonHoursEnd"
              >
              </el-date-picker>
              <el-checkbox
                style="margin-left: 20px"
                @change="radioChangePerson"
                v-model="noPersonHoursEnd"
                >长期</el-checkbox
              >
            </el-form-item>

            <el-form-item label="姓名" class="form-item" :rules="formRules.required" prop="qualificationJson.operatorsName">
              <el-input v-model="form.qualificationJson.operatorsName" @blur="blurOperatorsName"></el-input>
              <div  class="color-info">请填写姓名</div>
            </el-form-item>

            <el-form-item label="身份证号" class="form-item" :rules="formRules.IDNo" prop="qualificationJson.operatorsCardNo">
              <el-input v-model="form.qualificationJson.operatorsCardNo" ></el-input>
              <div  class="color-info">请填写身份证号</div>
            </el-form-item>

            <el-form-item label="手机号" class="form-item" :rules="formRules.phone" prop="qualificationJson.operatorsPhone">
              <el-input v-model="form.qualificationJson.operatorsPhone" ></el-input>
              <div  class="color-info">请填写手机号</div>
            </el-form-item>

            <el-form-item label="邮箱(选填)" class="form-item" :rules="formRules.email" prop="qualificationJson.operatorsEmail">
              <el-input v-model="form.qualificationJson.operatorsEmail" ></el-input>
              <div  class="color-info">邮箱(选填)</div>
            </el-form-item>
          </div>

          <div class="flex-item-form-header">
            <div class="content">结算信息</div>
          </div>


          <el-form-item label="账户类型" class="form-item" :rules="formRules.required" prop="settlementJson.bankType">
            <el-select :disabled="form.qualificationJson.companyType == 2" size="small" v-model="form.settlementJson.bankType" placeholder="请选择" style="width: 200px" @change="changeBankType">
              <el-option
                v-for="item in bankTypeArray"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="开户行" class="form-item" :rules="formRules.required" prop="settlementJson.bankName">
            <el-select
              width="400"
              v-model="form.settlementJson.bankName"
              filterable
              remote
              placeholder="请输入关键词"
              @change="bankChange"
              :remote-method="getBankNumberList"
              :loading="bankLoading">
              <el-option
                v-for="item in bankList"
                :key="item.id"
                :label="item.name"
                :value="item.name">
              </el-option>
            </el-select>
            <div class="color-info">如：中国银行</div>
          </el-form-item>
          <el-form-item
            label="开户行地区"
            :rules="formRules.required"
            class="form-item"
            prop="endareaList"
          >
            <el-cascader
              class="width-all"
              ref="bankAddress"
              v-model="form.endareaList"
              :props="{ lazy: true, value: 'id', label: 'name', emitPath:false, lazyLoad: lazyLoad2}"
              @change="regionBankChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="开户支行" class="form-item" :rules="formRules.required" prop="settlementJson.bankBranch">
            <el-select
              v-model="form.settlementJson.bankBranch"
              filterable
              remote
              placeholder="请输入关键词"
              @change="bankBranchChange"
              :remote-method="getBankBranchNumberList"
              :loading="bankLoading">
              <el-option
                v-for="item in bankBranchList"
                :key="item.id"
                :label="item.name"
                :value="item.name">
              </el-option>
            </el-select>
            <div class="color-info">如：深圳西乡支行</div>
          </el-form-item>

          <el-form-item label="户名" class="form-item" :rules="formRules.required" prop="settlementJson.bankHolderName">
            <el-input v-model="form.settlementJson.bankHolderName" disabled></el-input>
            <div  class="color-info">如：对公一般是公司名 对私一般是法人姓名</div>
          </el-form-item>
          <el-form-item label="账号" class="form-item max-width" :rules="formRules.backNo" prop="settlementJson.bankCard">
            <div class="back-card-wrap" style="max-width: 360px;width: 100%;">
              <el-input v-model="form.settlementJson.bankCard"
                        placeholder="开户银行支行名称（例：深圳西乡支行）"
                        maxlength="24"
                        @focus="bankCardFocus=true"
                        @blur="bankCardFocus=false">
              </el-input>
              <div class="back-card-no max-width" :class="{cursor:bankCardFocus}">{{bankCardNo}}</div>
            </div>
          </el-form-item>

          <el-form-item class="form-item"
                        v-if="form.settlementJson.bankType === 'public'"
                        :rules="formRules.required"
                        prop="settlementJson.openPermitImg"
                        label="开户许可证">
            <upload-images-one :file.sync="form.settlementJson.openPermitImg"
                               width="100px"
                               :show-hint-text="true"></upload-images-one>
          </el-form-item>

        </el-form>
      </div>
      <div class="text-center">
        <el-button type="primary" @click="submitCompanyUpdateForm">提交审核</el-button>
      </div>
    </el-drawer>

    <el-dialog title="修改密码" :visible.sync="dialogChangePwdVisible" width="30%">
      <el-form ref="formPassword" label-position="left" class="margin-top-30" label-width="80px" :model="formPassword" style="width: 360px;">
        <el-form-item label="旧密码" :rules="formRules.required" prop="oldPassword">
          <el-input v-model="formPassword.oldPassword" placeholder="请输入旧密码" show-password></el-input>
        </el-form-item>
        <el-form-item label="新密码" :rules="formRules.required" prop="newPassword">
          <el-input v-model="formPassword.newPassword" placeholder="请输入新密码" show-password></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogChangePwdVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirmChangePwd">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
  import { baseInfoLogo } from "@/config/imageSize";
  import {isEmpty} from '../../../assets/js/utils'
  import {supplierType,companyTypeArray,companyType,taxpayerTypeArray,taxpayerType,bankTypeArray,bankType,businessLicenseTypeArray} from '../../../assets/js/status'
  import {baseURL,URL,URL_MINOR} from '../../../config'
  import * as formRules from '../../../assets/js/formRules'
  import uploadImagesOne from '../../../components/public/upload/uploadImagesOne'
  import {returnBaseDataStoreInfoQualificationJson,returnBaseDataStoreInfoSettlementJson} from "../../../assets/js/modules/storeInfo/storeInfo";
  import MD5 from 'crypto-js/md5'

  export default {
    name: 'supplierStoreIndex',
    components:{
      uploadImagesOne,
    },
    data() {
      return {
        endData: '结束时间',
        loading: false,
        supplierType,
        companyType,
        companyTypeArray,
        taxpayerType,
        taxpayerTypeArray,
        bankType,
        businessLicenseTypeArray,
        bankTypeArray,
        /* 文件上传 */
        uploadData: {
          uploadUrl: baseURL + URL_MINOR.upload.uploadImage,
          headers: {
            token: this.$store.state.token,
          },
        },
        showDiv:0,
        hasLoadStoreInfo:false,
        hasLoadCompanyInfo:false,
        defaultLat:22.572369,
        defaultLng:	113.862587,
        /* 省市区选择 */
        visibleRegion: true,
        regionValue: [],
        formRules,
        mapObj:{},
        simpleInfo:{},
        storeInfo: {
          name:'',
          brief:'',
          logo:'',
          commentScore:0,
          provinceCode:0,
          cityCode:0,
          townCode:0,
          addressInfo:'',
          address:'',
          longitude:0,
          latitude:0,
        }, // 店铺信息
        area: '', // 所在区域信息
        qualificationJson:{},
        settlementJson:{},
        isUpdateCompanyInfo:false,
        showUpdateCompanyDialog:false,
        form:{
          qualificationJson:returnBaseDataStoreInfoQualificationJson(),
          settlementJson:returnBaseDataStoreInfoSettlementJson(),
          areaList:[],
          endareaList:[]
        },
        // 银行卡
        bankCardFocus:false,
        dialogChangePwdVisible:false,
        formPassword:{
          oldPassword:'',
          newPassword:'',
        },
        lastAuditStatus: 0,
        noPersonHoursEnd: false,
        noBusinessHoursEnd: false,
        bankLoading: false,
        bankList: [],
        bankBranchList: [],
        baseInfoLogo
      }
    },
    created() {
      this.getSimpleInfo();
    },
    mounted(){//调试右边的经营信息时候打开
      // this.showDiv = 1;
      // if (this.showDiv === 1){
      //   this.getCompanyInfo();
      // }
    },
    watch:{
      hasLoadStoreInfo:function(newValue){
        if(newValue){
          this.$nextTick(()=>{
            this.openMap();
          });
        }
      },
      showDiv:function(nv){
        if(nv === 0){
          this.$nextTick(()=>{
            this.openMap();
          });
        }else {
          if(!this.hasLoadCompanyInfo){
            this.getCompanyInfo()
          }
        }
      },
    },
    methods: {
      isEmpty,
      change(e) {
        // 视图未更新
        this.$forceUpdate();
      },
      regionChange(node) {
        [this.storeInfo.provinceCode, this.storeInfo.cityCode, this.storeInfo.areaCode, this.storeInfo.townCode] = node;
        this.getAddressLatLng();
      },
      getRegionSelection() {
        // let selection = this.$refs.region.getCheckedNodes()[0]
        let selection = '';
        let node = selection.path
        let labels = selection.pathLabels;
        return {node, labels, selection}
      },
      lazyLoad(node, resolve) {
        let levelMax = 4;
        let data = {}
        if (node.level === 0) {
          data.parentId = 0
        } else {
          data.parentId = node.data.id
        }

        this.axios.get(URL.region.list, {
          params: data
        }).then(res => {
          if (node.level >= (levelMax - 1)) res.data.forEach(item => {
            item.leaf = true
          })
          resolve(res.data)
        }).catch(() => {
          resolve([])
        })
      },
      getSimpleInfo(){
        this.loading = true;
        this.axios.get(URL.supplier.simpleInfo, {}).then(res => {
          if(res.data){
            this.simpleInfo = res.data;
            Object.assign(this.storeInfo,res.data);
            this.hasLoadStoreInfo = true;
            this.area = res.data.addressInfo.replace(res.data.address, '')
          }
        }).catch(res => {
        }).finally(() => {
          this.loading = false;
          if (this.simpleInfo.certificationStatus === 1){
            // this.getStoreInfo();
          }
        })
      },
      // getStoreInfo() {
      //   this.loading = true;
      //   this.axios.get(URL.supplier.storeInfo, {}).then(res => {
      //     let info = res.data || {};
      //     Object.assign(this.storeInfo,info);
      //     if(!isEmpty(info) && this.storeInfo.townCode){
      //       this.regionValue = [this.storeInfo.provinceCode,this.storeInfo.cityCode,this.storeInfo.areaCode,this.storeInfo.townCode];
      //     }
      //     this.hasLoadStoreInfo = true;
      //   }).catch(res => {
      //   }).finally(() => {
      //     this.loading = false;
      //   })
      // },
      getCompanyInfo(){
        this.loading = true;
        this.axios.get(URL.supplier.getSupplierQualification, {}).then(res => {
          if(res.code === 0){
            if (res.data.qualificationJson){
              let newArr = res.data.qualificationJson
              this.qualificationJson = newArr
              this.form.areaList = [newArr.businessProvinceId,newArr.businessCityId,newArr.businessCountyId]
            }
            if (res.data.settlementJson){
              let settlement = res.data.settlementJson
              this.settlementJson = settlement
              this.form.endareaList = [settlement.bankProvinceId,settlement.bankCityId]
            }
            this.isUpdateCompanyInfo = res.data.isUpdate === 1;
            this.lastAuditStatus = res.data.lastAuditStatus
          }
          this.hasLoadCompanyInfo = true;
        }).catch(res => {
        }).finally(() => {
          this.loading = false;
        })
      },
      editPassword(){//修改密码
        this.dialogChangePwdVisible = true;
      },
      openDrawer(){
        if(this.hasLoadCompanyInfo){
          this.showUpdateCompanyDialog = true;
          Object.assign(this.form.qualificationJson,this.qualificationJson);
          Object.assign(this.form.settlementJson,this.settlementJson);
          this.changeCompanyType(this.qualificationJson.companyType)
        }
      },
      submitCompanyUpdateForm(){
        if(this.form.qualificationJson.businessLicenseType == 1 && !this.form.qualificationJson.organizationImg){
          this.$message.success("请先上传组织机构代码证");
          return
        }
        this.$refs['companyUpdateForm'].validate((valid) => {
          if(valid){
            let data = Object.assign({},this.form);
            this.axios.post(URL.supplier.saveSupplierQualificationReview,data).then(res=>{
              if(res.code === 0){
                this.showUpdateCompanyDialog = false;
                this.$message.success("提交成功，请等待审核通过!~");
                this.isUpdateCompanyInfo = true;
              }else{
                this.$message.error(res.msg);
              }
              this.loading = false;
            }).catch(res=>{

            }).finally(res=>{
              this.loading = false;
            });
          }
        })
      },
      //初始化地图函数  自定义函数名init
      openMap() {
        //定义map变量 调用 qq.maps.Map() 构造函数   获取地图显示容器
        // let latitude = !isEmpty(this.storeInfo.latitude) ?  this.storeInfo.latitude : this.defaultLat;
        // let longitude = !isEmpty(this.storeInfo.longitude) ? this.storeInfo.longitude : this.defaultLng;
        // let center = new qq.maps.LatLng(latitude, longitude);
        // this.mapObj = new qq.maps.Map(document.getElementById('mapBox'),{
        //   center: center,
        //   zoom: 20
        // });

        // var marker = new qq.maps.Marker({
        //   position: center,
        //   map: this.mapObj
        // });

        // var infoWin = new qq.maps.InfoWindow({
        //   map: this.mapObj
        // });

        // infoWin.open();
        // infoWin.setContent('<div style="text-align:center;white-space:'+
        //   'nowrap;margin:10px;">经纬度: [' + latitude + ', ' +
        //   longitude + ']</div>');
        // infoWin.setPosition(center);

        // qq.maps.event.addListener(marker, 'click', function(event) {
        //       infoWin.open();
        //       infoWin.setContent('<div style="text-align:center;white-space:'+
        //           'nowrap;margin:10px;">经纬度:: [' + event.latLng.getLat() + ', ' +
        //           event.latLng.getLng() + ']</div>');
        //       infoWin.setPosition(event.latLng);
        // });
      },
      getAddressLatLng(){
        if(!this.storeInfo.townCode){
          return this.$message.error("请先选择区域")
        }
        this.storeInfo.addressInfo = this.getRegionSelection().labels.join("") + this.storeInfo.address;
        //获取经纬度
        let url = "https://apis.map.qq.com/ws/geocoder/v1/?key=ZN6BZ-SYPKU-JT3VF-BZUXO-K53ZE-5CFJE&address="+this.storeInfo.addressInfo;
        this.$jsonp(url,{output:"jsonp"}).then( res => {
          let explainInfo = res.result;
          this.storeInfo.latitude = explainInfo.location.lat;
          this.storeInfo.longitude = explainInfo.location.lng;
          this.openMap();
        }).catch(res => {
        }).finally(res => {
          this.loading = false;
        });
        if(!this.storeInfo.address){
          return this.$message.error({message:'请输入详细地址,然后重新点击定位',offset:500})
        }
      },
      /**
       * 修改表单
       * */
      // 主体类型
      changeCompanyType(e){

        let checkData = this.form;
        let qualificationJson = checkData.qualificationJson;
        let settlementJson = checkData.settlementJson;
        let bankHolderName = '';
        if(e === 2){ // 公司
          settlementJson.bankType = 'public';
          bankHolderName = qualificationJson.companyName || '';
          settlementJson.bankHolderName = bankHolderName;
        }else { // 个人
          settlementJson.bankType === 'private'
          if(settlementJson.bankType === 'public') {
            bankHolderName = qualificationJson.companyName || '';
          }else if(settlementJson.bankType === 'private'){
            bankHolderName = qualificationJson.operatorsName || '';
          }else if(!settlementJson.bankHolderName){
            settlementJson.bankHolderName = '';
          }
          settlementJson.bankHolderName = bankHolderName;
        }
      },
      // 结算类型
      changeBankType(e){
        let checkData = this.form;
        let qualificationJson = checkData.qualificationJson;
        let settlementJson = checkData.settlementJson;
        let bankHolderName = '';
        if(e === 'private') { // 私账
          bankHolderName = qualificationJson.operatorsName || ''
        }else { // 公账
          bankHolderName = qualificationJson.companyName || ''
        }
        settlementJson.bankHolderName = bankHolderName;
      },
      // 主体名称
      blurContactPerson(e){
        let checkData = this.form;
        let qualificationJson = checkData.qualificationJson;
        let settlementJson = checkData.settlementJson;
        if(settlementJson.bankType === 'public'){
          if(qualificationJson.companyName){
            settlementJson.bankHolderName = qualificationJson.companyName;
          }
        }
      },
      // 法人姓名
      blurOperatorsName(e){
        let checkData = this.form;
        let qualificationJson = checkData.qualificationJson;
        let settlementJson = checkData.settlementJson;
        if(settlementJson.bankType === 'private'){
          if(qualificationJson.operatorsName){
            settlementJson.bankHolderName = qualificationJson.operatorsName;
          }
        }
      },
      /**
       * 表单提交及重置-修改用户信息
       * @param formName
       */
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // this.storeInfo.addressInfo = this.getRegionSelection().labels.join("") + this.storeInfo.address;

            let data = {
              supplierId: this.storeInfo.id,
              brief: this.storeInfo.brief,
              logo: this.storeInfo.logo,
              address: this.storeInfo.address,
              name: this.storeInfo.name
            }
            this.loading = true;
            console.log('修改用户信息',data)
            this.axios.post(URL.supplier.editStoreInfo,data).then(res=>{
              if(res.code === 0){
                this.$message.success("保存成功");
              }else{
                this.$message.error(res.msg);
              }
              this.loading = false;
            }).catch(res=>{

            }).finally(res=>{
              this.loading = false;
            });
          } else {
            return false;
          }
        });
      },
      confirmChangePwd(){
        this.$refs.formPassword.validate((valid) => {
          if (valid) {
            let data = {
              oldPassword:MD5(this.formPassword.oldPassword).toString(),
              newPassword:MD5(this.formPassword.newPassword).toString(),
            };
            this.axios.post(URL.supplier.changePassword,this.qs.stringify(data)).then(res=>{
              if (res.code === 0){
                this.$message({
                  message: "下次登录的时候请使用新密码哦~",
                  type: "warning",
                });
                this.dialogChangePwdVisible = false;
              }
            });
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      radioChangePerson(e) {
        if (e) {
          this.form.qualificationJson.personHoursEnd = "";
        }
        this.noPersonHoursEnd = e;
      },
      // 地址获取回调
      lazyLoad2(node, resolve) {
        this.lazyLoad3(node, resolve, 2)
      },
      // 地址获取回调
      lazyLoad3(node, resolve, levelMax = 3) {
        let data = {};
        if (node.level === 0) {
          data.parentId = 0;
        } else {
          data.parentId = node.data.id;
        }
        this.axios
          .get(URL.region.list, {
            params: data
          })
          .then(res => {
            if (node.level >= levelMax - 1)
              res.data.forEach(item => {
                item.leaf = true;
              });
            resolve(res.data);
          })
          .catch(() => {
            resolve([]);
          });
      },
      //选中最后一级城市触发
      regionYYChange(node) {
        console.log(node);
        let nodes = this.$refs.businessAddress.getCheckedNodes()[0]
        console.log(nodes);
        this.form.qualificationJson.businessProvince = nodes.pathLabels[0]
        this.form.qualificationJson.businessCity = nodes.pathLabels[1]
        this.form.qualificationJson.businessCounty = nodes.pathLabels[2]
        this.form.qualificationJson.businessProvinceId = nodes.path[0]
        this.form.qualificationJson.businessCityId = nodes.path[1]
        this.form.qualificationJson.businessCountyId = nodes.path[2]
        this.form.areaList = [nodes.path[0],nodes.path[1],nodes.path[2]]
      },
      // 营业期限变化
      dateChange(e) {
        this.radio = '';
        this.endData = '结束时间';
      },
      // 经营时间选择长期逻辑
      radioChange(e) {
        if(e){
          this.radio = '1';
          this.form.qualificationJson.businessHoursEnd = '';
          this.endData = '长期';
        }else {
          this.radio = '';
          this.dateChange();
        }
      },
      bankTypeChange() {
        this.form.settlementJson.bankCode = ''
        this.form.settlementJson.bankBranchCode = ''
        this.bankList = [];
        this.bankBranchList = [];
      },
      bankChange(value) {
        this.form.settlementJson.bankBranchCode = ''
        this.bankBranchList = [];
        console.log('value',value)

        this.bankList.forEach((item) => {
          if (item.name == value) {
            this.form.settlementJson.bankName = item.name
            this.form.settlementJson.bankCode = item.id
            console.log('value',item.id)
            return false
          }
        })
      },
      bankBranchChange(value) {
        this.bankBranchList.forEach((item) => {
          if (item.name == value) {
            this.form.settlementJson.bankBranch = item.name
            this.form.settlementJson.bankBranchCode = item.id
            return false
          }
        })
      },
      getBankNumberList(name) {
        if (name !== '') {
          this.bankLoading = true
          this.axios.get(URL.adminSystemUser.getBankNumberList, {
            params: {
              name,
              status: 0
            }
          }).then(({data}) => {
            this.bankLoading = false
            this.bankList = data.records
          }).catch(() => {
            this.bankLoading = false
            this.bankList = [];
          })
        } else {
          this.bankList = [];
        }
      },
      getBankBranchNumberList(name) {
        if(!this.form.settlementJson.bankName){
          return this.$message.error('请先选择开户行')
        }
        if (name !== '') {
          this.bankLoading = true
          this.axios.get(URL.adminSystemUser.getBankBranchNumberList, {
            params: {
              name,
              bankId: this.form.settlementJson.bankCode
            }
          }).then(({data}) => {
            this.bankLoading = false
            this.bankBranchList = data.records
          }).catch(() => {
            this.bankLoading = false
            this.bankBranchList = [];
          })
        } else {
          this.bankBranchList = [];
        }
      },
      //选中最后一级城市触发
      regionBankChange(node) {
        console.log(node);
        let nodes = this.$refs.bankAddress.getCheckedNodes()[0]
        console.log(nodes);
        this.form.settlementJson.bankProvince = nodes.pathLabels[0]
        this.form.settlementJson.bankCity = nodes.pathLabels[1]
        this.form.settlementJson.bankProvinceId = nodes.path[0]
        this.form.settlementJson.bankCityId = nodes.path[1]
        this.form.endareaList = [nodes.path[0],nodes.path[1]]
      },
    },
    computed:{
      bankCardNo(){
        let bankCard = this.form.settlementJson.bankCard;
        if(!bankCard) return bankCard;
        let bankCardNo = '';
        bankCardNo = split(bankCard.match(/\S/ig));
        function split(arr = []){
          let len = 4;
          let str = '';
          if(!arr.length){
            str = '';
          } else if(arr.length<=len) {
            return arr.join('');
          }else{
            str = arr.splice(0,len).join('');
            str += '-';
            str += split(arr);
          }
          str = str.replace(/(^-)(-$)/,'');
          return str
        }
        return bankCardNo;
      },
    }
  }
</script>

<style lang="less" scoped>
  @import (reference) "../../../assets/css/data";
  .goods-slides{
    background-color: #f2f2f2;
  }
  .slide-image{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .goods-main-cover{
    display: block;
    width: 200px;
    max-width: 100%;
    height: auto;
    border: @border-width-solid solid @border-color-one;
  }

  .form-item{
    .el-input{
      max-width: 360px;
    }
  }

  .form-item{
    .el-cascader{
      max-width: 360px;
    }
  }

  .goods-details{
    border: @border-width-solid solid @border-color-one;
    padding: @padding-primary;
    img{
      max-width: 100%;
    }
    #mapBox{max-width:500px;max-height: 500px;}
  }

  .item-label-wrap{
    .item-label{
      font-size: small;
    }
    .active{
      color: #FF6146;
    }
  }

  .change_wrp{
    width: 300px;
    position: absolute;
    right: 35px;
    top: 35px;
    text-align: right;
  }

</style>
